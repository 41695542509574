const en = {
	home: 'Home',
	profile: 'Profile',
	account: 'Account',
	name: 'Name',
	email: 'Email',
	chooseLanguage: 'Choose your language',
	cancel: 'Cancel',
	save: 'Save',
	logout: 'Logout',
	english: 'English',
	german: 'Deutsch',
	privacyPolicy: 'Privacy Policy',
	termsAndConditions: 'Terms and Conditions',
	warning: 'Warning',
	leavePage: 'Any modifications you have made to this visit will be lost. Are you sure you want to continue?',
	leave: 'Leave',
	stay: 'Stay',
	visit: 'Visit',
	customer: 'Customer',
	followUp: 'Follow Up',
	date: 'Date',
	days: 'Days',
	noDataAvailable: 'No data available',
	noDataSelected: 'No data selected',
	btoTableTitle: 'BTO | Visit to the work site',
	gfbTableTitle: 'GFB | Risk Assessment',
	authCopy: 'The software for personnel service providers and temporary employement',
	welcomeBack: 'Welcome back to BTO/GFB',
	loginToContinue: 'Log in to your account to continue.',
	userName: 'Username',
	pleaseEnterYourUsername: 'Please enter your username',
	password: 'Password',
	pleaseEnterYourPassword: 'Please enter your password',
	login: 'Login',
	forgotPassword: 'Forgot Password?',
	copyright: 'Copyright 2023 Gedat. All rights reserved.',
	riskAssessment: 'Risk Assessment',
	cancelVisit: 'Cancel Visit',
	saveVisit: 'Save Visit',
	sendVisit: 'Send Visit',
	startVisit: 'Start Visit',
	startVisitNew: 'Start Visit New',
	cancelVisitGfb: 'Cancel Assessment',
	saveVisitGfb: 'Save Assessment',
	sendVisitGfb: 'Send Assessment',
	startVisitGfb: 'Start Assessment',
	startVisitNewGfb: 'Start New Assessment',
	startAssessment: 'Start Assessment',
	cancelVisitBto: 'Cancel Visit',
	saveVisitBto: 'Save Visit',
	sendVisitBto: 'Send Visit',
	startVisitBto: 'Start Visit',
	startVisitNewBto: 'Start New Visit',
	startAssessmentBto: 'Start Visit',
	chooseDate: 'Choose a date',
	MM_DD_YYYY: 'MM.DD.YYYY',
	visitStartedBy: 'Visit has been started by',
	workSystem: 'Work System',
	specificCharacteristics: 'Specific Characteristics',
	risksEvaluated: 'Risks Evaluated',
	workSiteMeasures: 'Work Site Measures',
	measures: 'Measures',
	technicalSafetyMeasures: 'Technical Safety Measures',
	prevention: 'Prevention',
	personalMeasures: 'Personal Measures',
	btoCheck: 'BTO-Check',
	presentAndAbsentStaff: 'Present and Absent Staff',
	workPlace: 'Work Place',
	workAndDrivingArea: 'Work and Driving Area',
	workEnvironment: 'Work Environment',
	jobAssignment: 'Job Assignment',
	workFlowProcess: 'Work Flow Process',
	input: 'Input',
	output: 'Output',
	workEquipmentAndUtilities: 'Work Equipment and Utilities',
	workingMaterials: 'Working Materials',
	qualifications: 'Qualifications',
	maxQualifications: 'Only 5 qualifications allowed',
	humanNecessaryQualifications: 'Human Necessary Qualifications',
	dangerousWorkplace: 'Dangerous Workplace (see DA 30)',
	onsiteSuperVisor: 'Onsite/MV/Supervisor/safe workplace',
	submit: 'Submit',
	searchByTitle: 'Search by title',
	searchQualifications: 'Search qualifications',
	searchEquipments: 'Search equipments',
	searchByName: 'Search by name',
	value: 'Value',
	healthPromotion: 'Health Promotion',
	riskFactors: 'Risk Factors',
	risk: 'Risk',
	ppe: 'PPE',
	occupationalMedicine: 'Occupational Medicine',
	searchPPE: 'Search PPE',
	searchMedicine: 'Search Medicine',
	searchRisks: 'Search Risks',
	description: 'Description',
	comment: 'Comment',
	ok: 'Ok',
	low: 'Low',
	medium: 'Medium',
	high: 'High',
	dangerClass: 'Danger Class',
	probability: 'Probability',
	selectRisk: 'Select Risk',
	damageSeverity: 'Damage Severity',
	workingOnNearElectricalSystems: 'Working on or near electrical systems',
	miscellaneous: 'Miscellaneous',
	ppeBorrower: 'Supplied by borrower',
	occupationalMedicineBorrower: 'Prompts Borrower',
	protectiveGlovesAgainstMech: 'Protective gloves against mech',
	earPlugsSingleUse: 'Ear plugs single use',
	noise: 'Noise',
	workOperatingEquipment: 'Work/Operating equipment',
	safetySpecialist: 'Safety specialist',
	firstAider: 'First aider',
	companyMedicalOfficer: 'Company medical officer',
	safetyOfficer: 'Safety officer',
	preceptorDriving: 'Preceptor driving assignment',
	cplInstructor: 'Instructor',
	cplProtectionAgainstSabotage: 'Protection Against Sabotage',
	cplSecrecyEconomy: 'Secrecy Economy',
	cplWeldingSupervisor: 'Welding Supervisor',
	cplRadiationProtection: 'Radiation Protection',
	cplAirCargo: 'Air Cargo',
	cplContactPerson: 'Contact Person',
	contactPerson: 'Please select the correct contact person',
	organizationalMeasures: 'Organizational Measures',
	noOrganizationalMeasuresSelected: 'No organizational measures selected',
	noEquipmentSelected: 'No equipment selected',
	noUserSelected: 'No user selected',
	answer: 'Answer',
	subject: 'Subject',
	yes: 'Yes',
	no: 'No',
	reason: 'Reason',
	employees: 'Employees',
	okCancelVisit: 'Ok, cancel visit',
	cancelDescription: 'Any filled-in data will be lost when the action is executed and the visit can begin again.',
	sendVisitIConfirm: 'I confirm that I have thoroughly checked and inspected everything:',
	sendVisitBulletOne: 'The current risk assessment',
	sendVisitBulletTwo: 'The presence of listed employees',
	sendVisitBulletThree: 'The safety-realted behavior of employees',
	sendVisitBulletFour: 'The propor condition of the PPE provided',
	sendVisitBulletFive: 'Further required measues see comments',
	sendVisitBulletSix: 'Comparison of activities including additional and secondary activities discussed with employees',
	sendVisitBulletSeven: 'Changes must be updated in the GFB',
	sendVisitBulletEight: 'Any changes are documented in the BTO, necessary measures are carried out immediately',
	sendVisitBulletNine: 'Changes are documented in the BTO and necessary measures are taken immediately',
	good: 'Good',
	bad: 'Bad',
	neutral: 'Neutral',
	evaluation: 'Evaluation',
	reasonForReview: 'Reason for review',
	result: 'Result',
	herebyConfirmGfb: 'I hereby confirm that I have thoroughly checked and inspected the workplace:',
	present: 'Present',
	ppeNotWorn: 'PPE not worn',
	nearAccident: 'Near accident / Unsafe situation',
	securityQuestions: 'Security Questions',
	notes: 'Notes',
	shortSummary: 'Short summary of the conversation',
	remarks: 'Remarks (f.e. missing medical measures, missing PPE, additional PPE, etc.)',
	firstAidFacilities: 'First aid facilities',
	instructionsByCustomer: 'Instructions by customer',
	dangerousWork: 'Dangerous work',
	dangerousMaterial: 'Dangerous material',
	customerCompliance: 'Customer is safety compliant',
	employeePointed: 'Employee is pointed to recognized dangers',
	customerPointed: 'Customer is pointed to recognized dangers',
	customerEliminatingDangers: 'Customer is eliminating recognized dangers',
	noExtraWork: 'No extra work without approval',
	noAssignment: 'No assignment change without approval',
	requiresMedicalMeasures: 'Requires new medical measures',
	selectEvaluation: 'Select evaluation',
	selectReason: 'Select reason',
	selectResult: 'Select result',
	selectLanguage: 'Select language',
	authentication: 'Authentication',
	authenticationFailed: 'Authentication failed. Please check your credentials and try again.',
	signOutFailed: 'Sign out failed. Please try again.',
	authenticationSuccessful: 'Authentication successful',
	detectedDangers: 'Detected dangers, additional activities',
	additionalInformation: 'Additional Information',
	noQualificationsSelected: 'No qualifications selected!',
	pendingStart: 'Visit is loading information and cannot be accessed yet. Please wait a moment.',
	visitFinished: 'Visit has been finished and cannot be edited anymore.'
};

const de = {
	home: 'Startseite',
	profile: 'Profil',
	account: 'Konto',
	name: 'Name',
	email: 'Email',
	chooseLanguage: 'Wählen Sie Ihre Sprache',
	cancel: 'Abbrechen',
	save: 'Speichern',
	logout: 'Abmelden',
	english: 'English',
	german: 'Deutsch',
	privacyPolicy: 'Datenschutzerklärung',
	termsAndConditions: 'Nutzungsbedingungen',
	warning: 'Warnung',
	leavePage: 'Alle Änderungen, die Sie an diesem Besuch vorgenommen haben, gehen verloren. Möchten Sie wirklich fortfahren?',
	leave: 'Verlassen',
	stay: 'Bleiben',
	visit: 'Besuch',
	customer: 'Kunde',
	followUp: 'Wiedervorlage',
	date: 'Datum',
	days: 'Tage',
	noDataAvailable: 'Keine Daten verfügbar',
	noDataSelected: 'Keine Daten ausgewählt',
	btoTableTitle: 'BTO | Besuch am Tätigkeitsort',
	gfbTableTitle: 'GFB | Gefährdungsbeurteilung',
	authCopy: 'Die Software für Personaldienstleister und Zeitarbeit',
	welcomeBack: 'Willkommen zurück bei BTO/GFB',
	loginToContinue: 'Melden Sie sich bei Ihrem Konto an, um fortzufahren.',
	userName: 'Benutzername',
	pleaseEnterYourUsername: 'Bitte geben Sie Ihren Benutzernamen ein',
	password: 'Passwort',
	pleaseEnterYourPassword: 'Bitte geben Sie Ihr Passwort ein',
	login: 'Anmelden',
	forgotPassword: 'Passwort vergessen?',
	copyright: 'Copyright 2023 Gedat. Alle Rechte vorbehalten.',
	riskAssessment: 'Risikobewertung',
	cancelVisitGfb: 'Beurteilung abbrechen',
	saveVisitGfb: 'Beurteilung speichern',
	sendVisitGfb: 'Beurteilung senden',
	startVisitGfb: 'Beurteilung starten',
	startVisitNewGfb: 'Beurteilung neu starten',
	startAssessment: 'Beurteilung starten',
	cancelVisitBto: 'Besuch abbrechen',
	saveVisitBto: 'Besuch speichern',
	sendVisitBto: 'Besuch senden',
	startVisitBto: 'Besuch starten',
	startVisitNewBto: 'Besuch neu starten',
	startAssessmentBto: 'Besuch starten',
	chooseDate: 'Datum auswählen',
	MM_DD_YYYY: 'TT.MM.JJJJ',
	visitStartedBy: 'Besuch wurde gestartet von',
	workSystem: 'Arbeitssystem',
	specificCharacteristics: 'Besondere Merkmale',
	risksEvaluated: 'Gefährdungen',
	workSiteMeasures: 'TO-Maßnahmen',
	measures: 'Maßnahmen',
	technicalSafetyMeasures: 'T-Maßnahmen',
	prevention: 'Prävention',
	personalMeasures: 'P-Maßnahmen',
	btoCheck: 'BTO-Check',
	presentAndAbsentStaff: 'Anwesend und Abwesend',
	workPlace: 'Arbeitsplatz',
	workAndDrivingArea: 'Arbeitsbereich und Fahrbereich FFZ',
	workEnvironment: 'Arbeitsumgebung',
	jobAssignment: 'Arbeitsaufgabe',
	workFlowProcess: 'Arbeitsablauf/Arbeitsorganisation',
	input: 'Eingabe',
	output: 'Ausgabe',
	workEquipmentAndUtilities: 'Arbeits- & Betriebsmittel',
	workingMaterials: 'Arbeitsstoffe',
	qualifications: 'Qualifikationen',
	maxQualifications: 'Es sind nur 5 Einträge zulässig',
	humanNecessaryQualifications: 'Mensch erforderliche Qualifikationen (Fachlich, körperlich)',
	dangerousWorkplace: 'Gefährlicher Arbeitsplatz (siehe DA 30)',
	onsiteSuperVisor: 'Onsite/MV/Einsatzbetreuer/ungefährlicher Arbeitsplatz',
	submit: 'Absenden',
	searchByTitle: 'Suche nach Titel',
	searchQualifications: 'Suche Qualifikationen',
	searchEquipments: 'Suche Ausrüstung',
	searchByName: 'Suche nach Name',
	value: 'Wert',
	healthPromotion: 'Gesundheitsförderung',
	riskFactors: 'Gefährdungsfaktoren',
	risk: 'Risiko',
	ppe: 'PSA',
	occupationalMedicine: 'Arbeitsmedizin',
	searchPPE: 'Suche PSA',
	searchMedicine: 'Suche Medizin',
	searchRisks: 'Suche Risiken',
	description: 'Beschreibung',
	comment: 'Bemerkungen',
	ok: 'Ok',
	low: 'Niedrig',
	medium: 'Mittel',
	high: 'Hoch',
	dangerClass: 'Gefahrenklasse',
	probability: 'Wahrscheinlichkeit',
	selectRisk: 'Wählen Sie ein Risiko',
	damageSeverity: 'Schadensschwere',
	workingOnNearElectricalSystems: 'Arbeiten an oder in der Nähe von elektrischen Systemen',
	miscellaneous: 'Sonstiges',
	ppeBorrower: 'Stellt Entleiher',
	occupationalMedicineBorrower: 'Veranlasst Entleiher',
	protectiveGlovesAgainstMech: 'Schutzhandschuhe gegen mechanische Risiken',
	earPlugsSingleUse: 'Ohrstöpsel Einweg',
	noise: 'Lärm',
	workOperatingEquipment: 'Arbeits-/Betriebsmittel',
	safetySpecialist: 'Sicherheitsfachkraft',
	firstAider: 'Ersthelfer / -in',
	companyMedicalOfficer: 'Betriebsarzt',
	safetyOfficer: 'Sicherheitsbeauftragter',
	preceptorDriving: 'Unterweiser Fahrbeauftragung',
	cplInstructor: 'Unterweiser',
	cplProtectionAgainstSabotage: 'Sabotageschutz',
	cplSecrecyEconomy: 'Geheimhaltung i. d. Wirtschaft',
	cplWeldingSupervisor: 'Schweißaufsicht',
	cplRadiationProtection: 'Stahlenschutzbeauftragter',
	cplAirCargo: 'SIB Luftfrachtsicherheit',
	cplContactPerson: 'Ansprechpartner',
	contactPerson: 'Bitte wählen Sie die Kontaktperson aus',
	organizationalMeasures: 'Organisatorische Maßnahmen',
	noOrganizationalMeasuresSelected: 'Keine organisatorischen Maßnahmen ausgewählt',
	noEquipmentSelected: 'Keine Ausrüstung ausgewählt',
	noUserSelected: 'Kein Benutzer ausgewählt',
	answer: 'Antwort',
	subject: 'Thema',
	yes: 'Ja',
	no: 'Nein',
	reason: 'Grund',
	employees: 'Mitarbeiter',
	okCancelVisit: 'Ok, Besuch abbrechen',
	cancelDescription: 'Ich bestätige die Überprüfung und in Augenscheinname',
	sendVisitIConfirm: 'Ich bestätige die Überprüfung und in Augenscheinname:',
	sendVisitBulletOne: 'der aktuellen Gefährdungsbeurteilung',
	sendVisitBulletTwo: 'der Anwesenheit gelisteter Mitarbeiter',
	sendVisitBulletThree: 'des sicherheitsgerechten Verhalten der Mitarbeiter',
	sendVisitBulletFour: 'des ordnungsgemäßen Zustandes ausgehändigter PSA',
	sendVisitBulletFive: 'weiterer erforderlicher Maßnahmen/siehe Anmerkungen',
	sendVisitBulletSix: 'BTO wurde am Beschäftigungsort durchgeführt',
	sendVisitBulletSeven: 'Abgleich Tätigkeiten inkl. Zusatz- und Nebentätigkeiten mit Mitarbeiter/in besprochen',
	sendVisitBulletEight: 'Änderungen müssen in der GFB aktualisiert werden',
	sendVisitBulletNine: 'Änderungen sind im BTO dokumentiert, erforderliche Maßnahmen werden umgehend durchgeführt',
	good: 'Gut',
	bad: 'Schlecht',
	neutral: 'Neutral',
	evaluation: 'Bewertung',
	reasonForReview: 'Grund für die Überprüfung',
	result: 'Ergebnis',
	herebyConfirmGfb: 'Ich bestätige hiermit, dass ich den Arbeitsplatz gründlich geprüft und inspiziert habe:',
	present: 'Anwesend',
	ppeNotWorn: 'PSA nicht getragen',
	nearAccident: 'Beinaheunfall / Unsichere Situation',
	securityQuestions: 'Sicherheitsfragen',
	notes: 'Bemerkungen',
	shortSummary: 'Kurze Zusammenfassung der Unterhaltung',
	remarks: 'Anmerkungen (z.B. zu arbeitsmedizinischen Maßnahmen, fehlende PSA, zusätzliche PSA, etc.)',
	firstAidFacilities: 'Erste Hilfe Einrichtungen',
	instructionsByCustomer: 'Einweisungen durch Kunden',
	dangerousWork: 'Gefährliche Arbeiten',
	dangerousMaterial: 'Gefährliche Stoffe',
	customerCompliance: 'Kunde hält Regeln des Arbeitsschutzes ein',
	employeePointed: 'Mitarbeiter auf erkannte Gefahren hingewiesen',
	customerPointed: 'Kunde auf erkannte Gefahren hingewiesen',
	customerEliminatingDangers: 'Kunde beseitigt erkannte Gefahren',
	noExtraWork: 'Keine Mehrarbeit ohne Zustimmung',
	noAssignment: 'Kein Einsatzwechsel ohne Zustimmung',
	requiresMedicalMeasures: 'Neue arbeitsmedizinische Maßnahmen erforderlich',
	selectEvaluation: 'Bewertung auswählen',
	selectReason: 'Grund auswählen',
	selectResult: 'Ergebnis auswählen',
	selectLanguage: 'Sprache auswählen',
	authentication: 'Authentifizierung',
	authenticationFailed: 'Authentifizierung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten und versuchen Sie es erneut.',
	signOutFailed: 'Abmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
	authenticationSuccessful: 'Authentifizierung erfolgreich',
	detectedDangers: 'Festgestellte Gefahren, zusätzliche Tätigkeiten',
	additionalInformation: 'Sonstiges',
	noQualificationsSelected: 'Keine Qualifikationen ausgewählt!',
	pendingStart: 'Der Besuch lädt Informationen und kann noch nicht aufgerufen werden. Bitte warten Sie einen Moment.',
	visitFinished: 'Der Besuch wurde beendet und kann nicht mehr bearbeitet werden.'
};

const translations = {
	en,
	de
};

export default translations;
