<div class="tj-breadcrumb" *ngIf="device === 'desktop' && shouldRender">
	<div class="tj-breadcrumb__container">
		<a href="/home" class="tj-breadcrumb__item" (click)="goto($event, 'home')">
			<i class="moon-home"></i>
			<span>{{'home' | translate }}</span>
		</a>
		<ng-template ngFor let-item [ngForOf]="breadcrumb.locations" let-i="index">
			<a [href]="item.path" class="tj-breadcrumb__item" (click)="goto($event, item.path)">
				<i *ngIf="item.icon" [class]="item.icon"></i>
				<span>{{ item.name }}</span>
			</a>
		</ng-template>
	</div>
</div>
