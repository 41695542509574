import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Breadcrumb, BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	standalone: true,
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
	imports: [MatIconModule, CommonModule, TranslatePipe]
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	breadcrumb: Breadcrumb = { locations: [] };
	screenBreadcrumbService: Subscription | null = null;
	screenUserService: Subscription | null = null;
	shouldRender: boolean = false;

	constructor(
		private breadcrumbService: BreadcrumbService,
		private userService: UserService,
		public router: Router,
		private location: Location
	) {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.shouldRender = this.location.path() !== '/auth';

		router.events.subscribe(() => {
			this.shouldRender = this.location.path() !== '/auth';
		});
	}

	async ngOnInit(): Promise<void> {
		this.screenBreadcrumbService = this.breadcrumbService.breadcrumbSubject.subscribe((breadcrumb) => {
			this.breadcrumb = breadcrumb;
		});

		this.screenUserService = this.userService.userSubject.subscribe((user) => {
			if (user === undefined || user === null) {
				this.shouldRender = false;
				return;
			}
		});
	}

	ngOnDestroy(): void {
		this.screenBreadcrumbService?.unsubscribe();
		this.screenUserService?.unsubscribe();
	}

	goto(event: MouseEvent, location: string | undefined): void {
		event.preventDefault();
		event.stopPropagation();

		if (location) this.router.navigate([location]);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
	}
}
